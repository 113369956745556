
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexSclRknrM7ZbkKWi0sE3YzCw2__24rvH3B__45et4UHK1gMeta } from "/home/portal/www/frontend/pages/index.vue?macro=true";
import { default as _91id_93H4XczHhMxCVsD4_45G_45TJz54OX7MVlUrCJNmQTYezZluMMeta } from "/home/portal/www/frontend/pages/form/[id].vue?macro=true";
import { default as _91id_93RvvJmT0zA7MKXoxmDMGA2mQ0Sxy33RBcNrbdj4VorCEMeta } from "/home/portal/www/frontend/pages/media/[id].vue?macro=true";
import { default as index__45xrDqPJaX_3w9SpwKgcdwPSVsaZF9EIqHRD_71_45TpIMeta } from "/home/portal/www/frontend/pages/start/index.vue?macro=true";
import { default as index7lOt4fmsOfIKL0mRO0RUpsX9BdEq9o02J6HsiVVYbE8Meta } from "/home/portal/www/frontend/pages/search/index.vue?macro=true";
import { default as indexXq14sJCdXECMULhBbrewTTOMvDIZq8Iq7j7siJbLSOUMeta } from "/home/portal/www/frontend/pages/article/index.vue?macro=true";
import { default as indexoP9wQaQRaKpDG1Ap_45Z2gg8G9bI6INbiyo_fzYBfQoagMeta } from "/home/portal/www/frontend/pages/benefit/index.vue?macro=true";
import { default as indexUs7WLz8vPk5LUSPfZVoUJ87XfHlgiYTTndM4C3aD910Meta } from "/home/portal/www/frontend/pages/benefits/index.vue?macro=true";
import { default as index_vkJpxslQ5vzi6agqXH4nZCSTf4xYuAMJAV77RViMe4Meta } from "/home/portal/www/frontend/pages/magazine/index.vue?macro=true";
import { default as indexk_45WgiTgK0tG4UsmzpeyQbH37Ua8zHNzFxI8XZbNz3p4Meta } from "/home/portal/www/frontend/pages/[...slug]/index.vue?macro=true";
import { default as indexs5MfRP7yF18e3FtzIJ90Oq2FeH1W4wksGMde_45nmMS2AMeta } from "/home/portal/www/frontend/pages/user/[id]/index.vue?macro=true";
import { default as indexA9pMq1_0hYzIvsInQXHQ8dSp30H7OpbUviQUr_WS4jsMeta } from "/home/portal/www/frontend/pages/node/[nid]/index.vue?macro=true";
import { default as indexDnD1OHzZbR8fJn4o_3MTY8xkuZn80k8LnV5FdTfTYo4Meta } from "/home/portal/www/frontend/pages/user/login/index.vue?macro=true";
import { default as indexQr_DV3k0OhsQ5SJEPy2gChGd0n26QLGyoUlpeU5V3BUMeta } from "/home/portal/www/frontend/pages/user/reset/index.vue?macro=true";
import { default as indexbVnwWFXHBR_45Y20_454YxXKijVAlzr31O9jmLFRfX1ZzBkMeta } from "/home/portal/www/frontend/pages/user/register/index.vue?macro=true";
import { default as indexnd2yQM89SgCX8Vl_TfiiivBkZjzLi5jGCi5cQG3hXaEMeta } from "/home/portal/www/frontend/pages/benefitsOverview/index.vue?macro=true";
import { default as _91id_930Fadx6bKBH_45UwEfiOIpWwCd8S_J6A2ULWfYwYX5V10wMeta } from "/home/portal/www/frontend/pages/form/confirmation/[id].vue?macro=true";
import { default as indexx_45xlwSGDb6Z_Xn079aVQiVjwKAtw46p14OaQN_gumgUMeta } from "/home/portal/www/frontend/pages/magazineOverview/index.vue?macro=true";
import { default as indexjZMb5nq5avMaVcpjtEdPaqwk5lMaSe3P2Keh3HM7F20Meta } from "/home/portal/www/frontend/pages/checkout/validate/index.vue?macro=true";
import { default as _91_91login_93_939qCQ2qqT56aTDKxexdXVb5ErE8OoDoiW87XBJ_XYGBQMeta } from "/home/portal/www/frontend/pages/user/reset/[id]/[timestamp]/[hash]/[[login]].vue?macro=true";
import { default as component_45stubcoN2_45U6zD7XkVDbP_XXduwYrPqf3Iu4oedURhdsze7YMeta } from "/home/portal/www/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubcoN2_45U6zD7XkVDbP_XXduwYrPqf3Iu4oedURhdsze7Y } from "/home/portal/www/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "home",
    path: "/",
    meta: indexSclRknrM7ZbkKWi0sE3YzCw2__24rvH3B__45et4UHK1gMeta || {},
    component: () => import("/home/portal/www/frontend/pages/index.vue")
  },
  {
    name: "webform-route",
    path: "/form/:id()",
    meta: _91id_93H4XczHhMxCVsD4_45G_45TJz54OX7MVlUrCJNmQTYezZluMMeta || {},
    component: () => import("/home/portal/www/frontend/pages/form/[id].vue")
  },
  {
    name: "media-id",
    path: "/media/:id()",
    component: () => import("/home/portal/www/frontend/pages/media/[id].vue")
  },
  {
    name: "start",
    path: "/start",
    meta: index__45xrDqPJaX_3w9SpwKgcdwPSVsaZF9EIqHRD_71_45TpIMeta || {},
    component: () => import("/home/portal/www/frontend/pages/start/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: index7lOt4fmsOfIKL0mRO0RUpsX9BdEq9o02J6HsiVVYbE8Meta || {},
    component: () => import("/home/portal/www/frontend/pages/search/index.vue")
  },
  {
    name: "node-article",
    path: "/article",
    meta: indexXq14sJCdXECMULhBbrewTTOMvDIZq8Iq7j7siJbLSOUMeta || {},
    component: () => import("/home/portal/www/frontend/pages/article/index.vue")
  },
  {
    name: "node-benefit",
    path: "/benefit",
    meta: indexoP9wQaQRaKpDG1Ap_45Z2gg8G9bI6INbiyo_fzYBfQoagMeta || {},
    component: () => import("/home/portal/www/frontend/pages/benefit/index.vue")
  },
  {
    name: "taxonomy-benefits",
    path: "/benefits",
    meta: indexUs7WLz8vPk5LUSPfZVoUJ87XfHlgiYTTndM4C3aD910Meta || {},
    component: () => import("/home/portal/www/frontend/pages/benefits/index.vue")
  },
  {
    name: "taxonomy-categories",
    path: "/magazine",
    meta: index_vkJpxslQ5vzi6agqXH4nZCSTf4xYuAMJAV77RViMe4Meta || {},
    component: () => import("/home/portal/www/frontend/pages/magazine/index.vue")
  },
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexk_45WgiTgK0tG4UsmzpeyQbH37Ua8zHNzFxI8XZbNz3p4Meta || {},
    component: () => import("/home/portal/www/frontend/pages/[...slug]/index.vue")
  },
  {
    name: "user-overview",
    path: "/user/:id()",
    meta: indexs5MfRP7yF18e3FtzIJ90Oq2FeH1W4wksGMde_45nmMS2AMeta || {},
    component: () => import("/home/portal/www/frontend/pages/user/[id]/index.vue")
  },
  {
    name: "node-canonical",
    path: "/node/:nid()",
    meta: indexA9pMq1_0hYzIvsInQXHQ8dSp30H7OpbUviQUr_WS4jsMeta || {},
    component: () => import("/home/portal/www/frontend/pages/node/[nid]/index.vue")
  },
  {
    name: "login",
    path: "/user/login",
    meta: indexDnD1OHzZbR8fJn4o_3MTY8xkuZn80k8LnV5FdTfTYo4Meta || {},
    component: () => import("/home/portal/www/frontend/pages/user/login/index.vue")
  },
  {
    name: "user-reset",
    path: "/user/reset",
    meta: indexQr_DV3k0OhsQ5SJEPy2gChGd0n26QLGyoUlpeU5V3BUMeta || {},
    component: () => import("/home/portal/www/frontend/pages/user/reset/index.vue")
  },
  {
    name: "register",
    path: "/user/register",
    meta: indexbVnwWFXHBR_45Y20_454YxXKijVAlzr31O9jmLFRfX1ZzBkMeta || {},
    component: () => import("/home/portal/www/frontend/pages/user/register/index.vue")
  },
  {
    name: "benefits-overview",
    path: "/benefitsOverview",
    meta: indexnd2yQM89SgCX8Vl_TfiiivBkZjzLi5jGCi5cQG3hXaEMeta || {},
    component: () => import("/home/portal/www/frontend/pages/benefitsOverview/index.vue")
  },
  {
    name: "webform-confirmation",
    path: "/form/confirmation/:id()",
    meta: _91id_930Fadx6bKBH_45UwEfiOIpWwCd8S_J6A2ULWfYwYX5V10wMeta || {},
    component: () => import("/home/portal/www/frontend/pages/form/confirmation/[id].vue")
  },
  {
    name: "magazine-overview",
    path: "/magazineOverview",
    meta: indexx_45xlwSGDb6Z_Xn079aVQiVjwKAtw46p14OaQN_gumgUMeta || {},
    component: () => import("/home/portal/www/frontend/pages/magazineOverview/index.vue")
  },
  {
    name: "checkout-validate",
    path: "/checkout/validate",
    component: () => import("/home/portal/www/frontend/pages/checkout/validate/index.vue")
  },
  {
    name: "user-reset-id-timestamp-hash-login",
    path: "/user/reset/:id()/:timestamp()/:hash()/:login?",
    component: () => import("/home/portal/www/frontend/pages/user/reset/[id]/[timestamp]/[hash]/[[login]].vue")
  },
  {
    name: component_45stubcoN2_45U6zD7XkVDbP_XXduwYrPqf3Iu4oedURhdsze7YMeta?.name,
    path: "/account/register",
    component: component_45stubcoN2_45U6zD7XkVDbP_XXduwYrPqf3Iu4oedURhdsze7Y
  },
  {
    name: component_45stubcoN2_45U6zD7XkVDbP_XXduwYrPqf3Iu4oedURhdsze7YMeta?.name,
    path: "/account/login",
    component: component_45stubcoN2_45U6zD7XkVDbP_XXduwYrPqf3Iu4oedURhdsze7Y
  },
  {
    name: component_45stubcoN2_45U6zD7XkVDbP_XXduwYrPqf3Iu4oedURhdsze7YMeta?.name,
    path: "/account",
    component: component_45stubcoN2_45U6zD7XkVDbP_XXduwYrPqf3Iu4oedURhdsze7Y
  }
]