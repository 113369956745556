import provideState_QdJe8OSSD_8wGCIAC_njNx2RCgNRiM6pPcjiRmvk3ic from "/home/portal/www/frontend/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/portal/www/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/portal/www/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/portal/www/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/portal/www/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/portal/www/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/home/portal/www/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/portal/www/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/portal/www/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/portal/www/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_kNbg80s6qe3kc87z2nZCvKsWnzwgKnky7UDHZZ7lmIY from "/home/portal/www/frontend/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import language_eYKsUBVhhbAQxkgEQfGaQ9TEZ_6EZsjgtNqWUANErGs from "/home/portal/www/frontend/node_modules/nuxt-language-negotiation/dist/runtime/plugins/language.mjs";
import blokkliEditable_yH5y4jhW0pnh4qvyTY4vuxu7TVm4XVykmgjFQa3nF8Q from "/home/portal/www/frontend/node_modules/@blokkli/editor/dist/runtime/plugins/blokkliEditable.js";
import recaptcha_vq_SqlhdLs4FUNHaAfRS_nsTXv2Kinr4Q_VI_3s7mjg from "/home/portal/www/frontend/plugins/recaptcha.ts";
import clickaway_ngS3d0J7NpiDHstVB3CpeVLXzJtj82x0rIRLxAZKwSc from "/home/portal/www/frontend/plugins/clickaway.ts";
import graphqlMiddleware_iWFzsP0MfTNrSebqRVAXpE_gZdmmQS8KQ8EW3l_Xcog from "/home/portal/www/frontend/plugins/graphqlMiddleware.ts";
import drupalUser_csK0kmJtoe8MCRdEph29jVmq41JxUUPSrLlC7fbBQ0A from "/home/portal/www/frontend/plugins/drupalUser.ts";
import texts_lnAjn_dGKwYBMfpVPhpA1VKHaMBwEGKQeF7B1giuAoA from "/home/portal/www/frontend/node_modules/nuxt-easy-texts/dist/runtime/plugins/texts.mjs";
import formkitLanguage_l4AsNQ_K9Z0RGUpjlUJjzUyFDbp0QJ7peaaTqgOK1ZM from "/home/portal/www/frontend/plugins/formkitLanguage.ts";
import gtm_client_MQaOARgMghyVgOHvZC5M5hQI1nTisUfzlPHTlOKu0TA from "/home/portal/www/frontend/plugins/gtm.client.ts";
import oruga_jaqLjvPyVoRFDX2kr03blq2bfiVk5bqKJm78lLDUmFY from "/home/portal/www/frontend/plugins/oruga.ts";
export default [
  provideState_QdJe8OSSD_8wGCIAC_njNx2RCgNRiM6pPcjiRmvk3ic,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_client_kNbg80s6qe3kc87z2nZCvKsWnzwgKnky7UDHZZ7lmIY,
  language_eYKsUBVhhbAQxkgEQfGaQ9TEZ_6EZsjgtNqWUANErGs,
  blokkliEditable_yH5y4jhW0pnh4qvyTY4vuxu7TVm4XVykmgjFQa3nF8Q,
  recaptcha_vq_SqlhdLs4FUNHaAfRS_nsTXv2Kinr4Q_VI_3s7mjg,
  clickaway_ngS3d0J7NpiDHstVB3CpeVLXzJtj82x0rIRLxAZKwSc,
  graphqlMiddleware_iWFzsP0MfTNrSebqRVAXpE_gZdmmQS8KQ8EW3l_Xcog,
  drupalUser_csK0kmJtoe8MCRdEph29jVmq41JxUUPSrLlC7fbBQ0A,
  texts_lnAjn_dGKwYBMfpVPhpA1VKHaMBwEGKQeF7B1giuAoA,
  formkitLanguage_l4AsNQ_K9Z0RGUpjlUJjzUyFDbp0QJ7peaaTqgOK1ZM,
  gtm_client_MQaOARgMghyVgOHvZC5M5hQI1nTisUfzlPHTlOKu0TA,
  oruga_jaqLjvPyVoRFDX2kr03blq2bfiVk5bqKJm78lLDUmFY
]