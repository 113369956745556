// --------------------------------------------------------------------------------
// Enums
// --------------------------------------------------------------------------------

/**
 * @example
 * ```graphql
 * enum EntityQueryOperator {
 *   BETWEEN
 *   CONTAINS
 *   ENDS_WITH
 *   EQUAL
 *   GREATER_THAN
 *   GREATER_THAN_OR_EQUAL
 *   IN
 *   IS_NOT_NULL
 *   IS_NULL
 *   LIKE
 *   NOT_BETWEEN
 *   NOT_EQUAL
 *   NOT_IN
 *   NOT_LIKE
 *   REGEXP
 *   SMALLER_THAN
 *   SMALLER_THAN_OR_EQUAL
 *   STARTS_WITH
 * }
 * ```
 */
export const EntityQueryOperator = Object.freeze({
  BETWEEN: 'BETWEEN',
  CONTAINS: 'CONTAINS',
  ENDS_WITH: 'ENDS_WITH',
  EQUAL: 'EQUAL',
  GREATER_THAN: 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL: 'GREATER_THAN_OR_EQUAL',
  IN: 'IN',
  IS_NOT_NULL: 'IS_NOT_NULL',
  IS_NULL: 'IS_NULL',
  LIKE: 'LIKE',
  NOT_BETWEEN: 'NOT_BETWEEN',
  NOT_EQUAL: 'NOT_EQUAL',
  NOT_IN: 'NOT_IN',
  NOT_LIKE: 'NOT_LIKE',
  REGEXP: 'REGEXP',
  SMALLER_THAN: 'SMALLER_THAN',
  SMALLER_THAN_OR_EQUAL: 'SMALLER_THAN_OR_EQUAL',
  STARTS_WITH: 'STARTS_WITH'
});

/**
 * @example
 * ```graphql
 * enum EntityType {
 *   """Content block"""
 *   BLOCK_CONTENT
 * 
 *   """Kommentar"""
 *   COMMENT
 * 
 *   """Sprache"""
 *   CONFIGURABLE_LANGUAGE
 * 
 *   """Datei"""
 *   FILE
 * 
 *   """Medien"""
 *   MEDIA
 * 
 *   """Menü"""
 *   MENU
 * 
 *   """Benutzerdefinierter Menülink"""
 *   MENU_LINK_CONTENT
 * 
 *   """Inhalt"""
 *   NODE
 * 
 *   """Seitenabschnitt"""
 *   PARAGRAPH
 * 
 *   """Paragraphs Blokkli Edit State"""
 *   PARAGRAPHS_BLOKKLI_EDIT_STATE
 * 
 *   """Seitenabschnittsbibliothekseintrag"""
 *   PARAGRAPHS_LIBRARY_ITEM
 * 
 *   """Seitenabschnittstyp"""
 *   PARAGRAPHS_TYPE
 * 
 *   """Umleiten"""
 *   REDIRECT
 * 
 *   """Rokka Metadata"""
 *   ROKKA_METADATA
 * 
 *   """Taxonomie-Begriff"""
 *   TAXONOMY_TERM
 * 
 *   """Texts"""
 *   TEXTS
 * 
 *   """Translatable config pages"""
 *   TRANSLATABLE_CONFIG_PAGES
 * 
 *   """Benutzer"""
 *   USER
 * 
 *   """Ansicht"""
 *   VIEW
 * 
 *   """Voucher"""
 *   VOUCHER
 * 
 *   """Webform"""
 *   WEBFORM
 * }
 * ```
 */
export const EntityType = Object.freeze({
  /** Content block */
  BLOCK_CONTENT: 'BLOCK_CONTENT',
  /** Kommentar */
  COMMENT: 'COMMENT',
  /** Sprache */
  CONFIGURABLE_LANGUAGE: 'CONFIGURABLE_LANGUAGE',
  /** Datei */
  FILE: 'FILE',
  /** Medien */
  MEDIA: 'MEDIA',
  /** Menü */
  MENU: 'MENU',
  /** Benutzerdefinierter Menülink */
  MENU_LINK_CONTENT: 'MENU_LINK_CONTENT',
  /** Inhalt */
  NODE: 'NODE',
  /** Seitenabschnitt */
  PARAGRAPH: 'PARAGRAPH',
  /** Paragraphs Blokkli Edit State */
  PARAGRAPHS_BLOKKLI_EDIT_STATE: 'PARAGRAPHS_BLOKKLI_EDIT_STATE',
  /** Seitenabschnittsbibliothekseintrag */
  PARAGRAPHS_LIBRARY_ITEM: 'PARAGRAPHS_LIBRARY_ITEM',
  /** Seitenabschnittstyp */
  PARAGRAPHS_TYPE: 'PARAGRAPHS_TYPE',
  /** Umleiten */
  REDIRECT: 'REDIRECT',
  /** Rokka Metadata */
  ROKKA_METADATA: 'ROKKA_METADATA',
  /** Taxonomie-Begriff */
  TAXONOMY_TERM: 'TAXONOMY_TERM',
  /** Texts */
  TEXTS: 'TEXTS',
  /** Translatable config pages */
  TRANSLATABLE_CONFIG_PAGES: 'TRANSLATABLE_CONFIG_PAGES',
  /** Benutzer */
  USER: 'USER',
  /** Ansicht */
  VIEW: 'VIEW',
  /** Voucher */
  VOUCHER: 'VOUCHER',
  /** Webform */
  WEBFORM: 'WEBFORM'
});

/**
 * @example
 * ```graphql
 * enum MenuName {
 *   """Links im Bezug auf das aktive Benutzerkonto"""
 *   ACCOUNT
 * 
 *   """Links für administrative Aufgaben"""
 *   ADMIN
 * 
 *   """Links zu Website-Informationen"""
 *   FOOTER
 *   FOOTER_EMPIRICUS
 * 
 *   """Site section links"""
 *   MAIN
 * 
 *   """Hauptnavigation empiricus"""
 *   MAIN_EMPIRICUS
 * 
 *   """Links zu Benutzerwerkzeugen, meist von Modulen hinzugefügt"""
 *   TOOLS
 * }
 * ```
 */
export const MenuName = Object.freeze({
  /** Links im Bezug auf das aktive Benutzerkonto */
  ACCOUNT: 'ACCOUNT',
  /** Links für administrative Aufgaben */
  ADMIN: 'ADMIN',
  /** Links zu Website-Informationen */
  FOOTER: 'FOOTER',
  FOOTER_EMPIRICUS: 'FOOTER_EMPIRICUS',
  /** Site section links */
  MAIN: 'MAIN',
  /** Hauptnavigation empiricus */
  MAIN_EMPIRICUS: 'MAIN_EMPIRICUS',
  /** Links zu Benutzerwerkzeugen, meist von Modulen hinzugefügt */
  TOOLS: 'TOOLS'
});

/**
 * @example
 * ```graphql
 * enum ParagraphsBlokkliEditableFieldConfigType {
 *   frame
 *   plain
 * }
 * ```
 */
export const ParagraphsBlokkliEditableFieldConfigType = Object.freeze({
  frame: 'frame',
  plain: 'plain'
});

/**
 * @example
 * ```graphql
 * enum ParagraphsBlokkliRemoteVideoProvider {
 *   VIMEO
 *   YOUTUBE
 * }
 * ```
 */
export const ParagraphsBlokkliRemoteVideoProvider = Object.freeze({
  VIMEO: 'VIMEO',
  YOUTUBE: 'YOUTUBE'
});

/**
 * @example
 * ```graphql
 * enum StaticEntityType {
 *   ACTIVATION_STEP1
 *   ACTIVATION_STEP3
 *   ACTIVATION_STEP4
 *   EMP_ACTIVATION_STEP1
 *   EMP_ACTIVATION_STEP3
 *   EMP_ACTIVATION_STEP4
 *   EMP_CLASSIC
 *   EMP_PREMIUM
 *   EMP_REGISTER_FINISH
 *   FS_CLASSIC
 *   FS_PREMIUM
 *   REGISTER_FINISH
 * }
 * ```
 */
export const StaticEntityType = Object.freeze({
  ACTIVATION_STEP1: 'ACTIVATION_STEP1',
  ACTIVATION_STEP3: 'ACTIVATION_STEP3',
  ACTIVATION_STEP4: 'ACTIVATION_STEP4',
  EMP_ACTIVATION_STEP1: 'EMP_ACTIVATION_STEP1',
  EMP_ACTIVATION_STEP3: 'EMP_ACTIVATION_STEP3',
  EMP_ACTIVATION_STEP4: 'EMP_ACTIVATION_STEP4',
  EMP_CLASSIC: 'EMP_CLASSIC',
  EMP_PREMIUM: 'EMP_PREMIUM',
  EMP_REGISTER_FINISH: 'EMP_REGISTER_FINISH',
  FS_CLASSIC: 'FS_CLASSIC',
  FS_PREMIUM: 'FS_PREMIUM',
  REGISTER_FINISH: 'REGISTER_FINISH'
});

/**
 * @example
 * ```graphql
 * enum WebformStateConditionTrigger {
 *   """Zwischen"""
 *   BETWEEN
 * 
 *   """Checked"""
 *   CHECKED
 * 
 *   """Leer"""
 *   EMPTY
 * 
 *   """Ausgefüllt"""
 *   FILLED
 * 
 *   """Größer als"""
 *   GREATER_THAN
 * 
 *   """Größer als/gleich"""
 *   GREATER_THAN_EQUAL_TO
 * 
 *   """Kleiner als"""
 *   LESS_THAN
 * 
 *   """Less than/Equal to"""
 *   LESS_THAN_EQUAL_TO
 * 
 *   """Nicht zwischen"""
 *   NOT_BETWEEN
 * 
 *   """Kein Muster"""
 *   NOT_PATTERN
 * 
 *   """Muster"""
 *   PATTERN
 * 
 *   """Unchecked"""
 *   UNCHECKED
 * 
 *   """Wert ist"""
 *   VALUE_IS
 * 
 *   """Wert ist nicht"""
 *   VALUE_IS_NOT
 * }
 * ```
 */
export const WebformStateConditionTrigger = Object.freeze({
  /** Zwischen */
  BETWEEN: 'BETWEEN',
  /** Checked */
  CHECKED: 'CHECKED',
  /** Leer */
  EMPTY: 'EMPTY',
  /** Ausgefüllt */
  FILLED: 'FILLED',
  /** Größer als */
  GREATER_THAN: 'GREATER_THAN',
  /** Größer als/gleich */
  GREATER_THAN_EQUAL_TO: 'GREATER_THAN_EQUAL_TO',
  /** Kleiner als */
  LESS_THAN: 'LESS_THAN',
  /** Less than/Equal to */
  LESS_THAN_EQUAL_TO: 'LESS_THAN_EQUAL_TO',
  /** Nicht zwischen */
  NOT_BETWEEN: 'NOT_BETWEEN',
  /** Kein Muster */
  NOT_PATTERN: 'NOT_PATTERN',
  /** Muster */
  PATTERN: 'PATTERN',
  /** Unchecked */
  UNCHECKED: 'UNCHECKED',
  /** Wert ist */
  VALUE_IS: 'VALUE_IS',
  /** Wert ist nicht */
  VALUE_IS_NOT: 'VALUE_IS_NOT'
});

/**
 * @example
 * ```graphql
 * enum WebformStateLogic {
 *   AND
 *   OR
 *   XOR
 * }
 * ```
 */
export const WebformStateLogic = Object.freeze({
  AND: 'AND',
  OR: 'OR',
  XOR: 'XOR'
});